import { useEffect, useRef, useState } from 'react';
import type { TpInterval } from '@noah-labs/shared-util-vanilla';

export function usePollReady(conditionFn: () => boolean): boolean {
  const [ready, setReady] = useState(false);
  const intervalRef = useRef<TpInterval | undefined>(undefined);

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      if (!conditionFn()) {
        return;
      }
      setReady(true);
      clearInterval(intervalRef.current);
    }, 250);

    return (): void => {
      clearInterval(intervalRef.current);
    };
  }, [conditionFn]);
  return ready;
}
