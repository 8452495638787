import { Fragment } from 'react';
import { Divider, Stack, Typography } from '@mui/material';
import { ConsentOption } from './ConsentOption';

export function ContactOptions(): React.ReactElement {
  return (
    <Stack spacing={2}>
      <Typography variant="paragraphBodyMBold">Contact</Typography>
      <ConsentOption
        label={{
          primary: <Fragment>Email</Fragment>,
          secondary: (
            <Fragment>
              We would like to inform you about NOAH point giveaways and promotional offers as well
              as new features and updates. We will never sell your data.
            </Fragment>
          ),
        }}
        name="EmailContact"
      />
      <Divider />
    </Stack>
  );
}
