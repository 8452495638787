import { getSafeLocalStorage } from '@noah-labs/fe-shared-util-shared';
import { logger } from '@noah-labs/shared-logger/browser';
import Cookies from 'js-cookie';

// taken from https://github.com/segmentio/analytics-next/blob/master/packages/browser/src/core/user/index.ts#L6
const segmentItems = ['ajs_user_id', 'ajs_user', 'ajs_user_traits'];

/**
 * Clear any Segment user data in local storage or cookies.
 * We use this function rather than calling analytics.reset() because that
 * function is async and requires .loaded to have been called before it resolves.
 * We need to clear the data before that point.
 * We have now set Segment to not persist user data but leaving this to clear out leftovers.
 * We also remove any intercom session cookies.
 */
export function clearSomeLocalStorageAndCookies(): void {
  const safeLS = getSafeLocalStorage();
  segmentItems.forEach((key) => {
    safeLS?.removeItem(key);
  });
  segmentItems.forEach((key) => {
    Cookies.remove(key);
  });

  const allCookies = Cookies.get();

  Object.keys(allCookies).forEach((cookieName) => {
    if (!cookieName.startsWith('intercom-session-')) {
      return;
    }
    Cookies.remove(cookieName, { path: '/' });
  });
  logger.success('clearing local segment user data');
}
