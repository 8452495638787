import type { ReactElement } from 'react';
import { AppBar, Link, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ButtonGroup } from '../buttons/ButtonGroup';
import { PrimaryButton } from '../buttons/PrimaryButton';
import { AppLogo } from '../images/AppLogo';
import { BusinessLogo } from '../images/BusinessLogo';

export type PpOnboardingHeader = {
  business?: boolean;
  helpButton?: React.ReactNode;
  href?: string;
  isProd: boolean;
};

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  borderRadius: theme.borderRadius.md,
  height: theme.shape.dashboardHeaderHeight,
  padding: theme.spacing(0, 2),
  position: 'relative',
  width: '100%',
}));

export function OnboardingHeader({
  business,
  helpButton,
  href,
  isProd,
}: PpOnboardingHeader): ReactElement {
  const NoahLogo = business ? <BusinessLogo /> : <AppLogo />;
  return (
    <StyledAppBar variant="outlined">
      <Stack alignItems="center" direction="row" height="100%" justifyContent="space-between">
        {href ? <Link href={href}>{NoahLogo}</Link> : NoahLogo}
        <ButtonGroup direction="row" gap={1}>
          {business && (
            <PrimaryButton href={href} LinkComponent={Link} size="small" variant="outlined">
              {isProd ? 'Switch to Sandbox' : 'Switch to live'}
            </PrimaryButton>
          )}
          {helpButton}
        </ButtonGroup>
      </Stack>
    </StyledAppBar>
  );
}
