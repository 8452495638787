import { AppEnvs, Network } from '@noah-labs/shared-schema-gql';

const cnTestMap: Record<Lowercase<Network>, Network> = {
  bitcoin: Network.BitcoinTest,
  bitcointest: Network.BitcoinTest,
  ethereum: Network.EthereumTestSepolia,
  ethereumtestsepolia: Network.EthereumTestSepolia,
  lightning: Network.LightningTest,
  lightningtest: Network.LightningTest,
  offnetwork: Network.OffNetwork,
  polygonpos: Network.PolygonTestAmoy,
  polygontestamoy: Network.PolygonTestAmoy,
  polygontestmumbai: Network.PolygonTestAmoy,
  solana: Network.Solana,
  solanadevnet: Network.SolanaDevnet,
  tron: Network.TronTestShasta,
  trontestshasta: Network.TronTestShasta,
};

const cnProdMap: Record<Lowercase<Network>, Network> = {
  bitcoin: Network.Bitcoin,
  bitcointest: Network.Bitcoin,
  ethereum: Network.Ethereum,
  ethereumtestsepolia: Network.Ethereum,
  lightning: Network.Lightning,
  lightningtest: Network.Lightning,
  offnetwork: Network.OffNetwork,
  polygonpos: Network.PolygonPos,
  polygontestamoy: Network.PolygonPos,
  polygontestmumbai: Network.PolygonPos,
  solana: Network.Solana,
  solanadevnet: Network.Solana,
  tron: Network.Tron,
  trontestshasta: Network.Tron,
};

export function isValidNetwork(network: string): network is Network {
  return Object.values(Network).includes(network as Network);
}

export function getEnvNetwork(code: Network | Lowercase<Network>, isProd: boolean): Network {
  const c = code.toLowerCase() as Lowercase<Network>;
  return isProd ? cnProdMap[c] : cnTestMap[c];
}

export function getAppEnvNetwork(code: Network | Lowercase<Network>, env: AppEnvs): Network {
  return getEnvNetwork(code, env === AppEnvs.Prod);
}
