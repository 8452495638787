import { isBrowser } from '@noah-labs/fe-shared-util-shared';

export function isIntercomBooted(): boolean {
  if (!isBrowser()) {
    return false;
  }
  return typeof window.Intercom === 'function' && window.Intercom.booted;
}

export function shutdownIntercom(): void {
  if (!isIntercomBooted()) {
    return;
  }

  window.Intercom('shutdown');
}

export type TpIntercomToggle = 'show' | 'hide';
export function toggleIntercom(cmd: TpIntercomToggle): void {
  if (!isIntercomBooted()) {
    return;
  }

  window.Intercom(cmd);
}
