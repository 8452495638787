import type { ReactElement } from 'react';
import { useCallback } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import type { TpCountriesForSelect } from '@noah-labs/fe-shared-data-access-countries';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';
import { PrimaryButton } from '../mui/buttons/PrimaryButton';
import { DialogSelectField } from '../mui/forms/DialogSelectField';
import type { TpSelectOption } from '../mui/forms/SearchSelect';

type PpChooseCountryForm = {
  countries: TpCountriesForSelect['countries'] | undefined;
  onNext: (CountryCode: string) => void;
};

const countrySchema = z.object({
  CountryCode: z.string().trim().min(1, 'Country is required'),
  CountryName: z.string().trim().min(1, 'Country is required'),
});

type TpCountrySchema = z.infer<typeof countrySchema>;

export function ChooseCountryForm({ countries, onNext }: PpChooseCountryForm): ReactElement {
  const methods = useForm<TpCountrySchema>({
    defaultValues: {
      CountryCode: '',
      CountryName: '',
    },
    mode: 'onBlur',
    resolver: zodResolver(countrySchema),
  });

  const handleCountryFormValues = useCallback(
    (value: TpSelectOption | null) => {
      methods.setValue('CountryCode', value?.value || '', {
        shouldValidate: true,
      });
      methods.setValue('CountryName', value?.label || '', {
        shouldValidate: true,
      });
    },

    [methods],
  );

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit((value) => onNext(value.CountryCode))}>
        <DialogSelectField
          fullWidth
          required
          helperText=" "
          isFetched={!!countries}
          label="Country"
          name="CountryName"
          options={countries}
          onChange={handleCountryFormValues}
        />
        <PrimaryButton disabled={!methods.formState.isValid} size="medium" type="submit">
          Continue
        </PrimaryButton>
      </form>
    </FormProvider>
  );
}
